@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Carnero-Regular";
  src: url("./ui/assets/fonts/Carnero-Regular.eot");
  src: url("./ui/assets/fonts/Carnero-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./ui/assets/fonts/Carnero-Regular.svg#Carnero-Regular") format("svg"),
    url("./ui/assets/fonts/Carnero-Regular.ttf") format("truetype"),
    url("./ui/assets/fonts/Carnero-Regular.woff") format("woff"),
    url("./ui/assets/fonts/Carnero-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Carnero-Black-Italic";
  src: url("./ui/assets/fonts/Carnero-BlackItalic.eot");
  src: url("./ui/assets/fonts/Carnero-BlackItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./ui/assets/fonts/Carnero-BlackItalic.svg#Carnero-BlackItalic")
      format("svg"),
    url("./ui/assets/fonts/Carnero-BlackItalic.ttf") format("truetype"),
    url("./ui/assets/fonts/Carnero-BlackItalic.woff") format("woff"),
    url("./ui/assets/fonts/Carnero-BlackItalic.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Carnero-Black";
  src: url("./ui/assets/fonts/Carnero-Black.eot");
  src: url("./ui/assets/fonts/Carnero-Black.eot?#iefix")
      format("embedded-opentype"),
    url("./ui/assets/fonts/Carnero-Black.svg#Carnero-Black") format("svg"),
    url("./ui/assets/fonts/Carnero-Black.ttf") format("truetype"),
    url("./ui/assets/fonts/Carnero-Black.woff") format("woff"),
    url("./ui/assets/fonts/Carnero-Black.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Carnero-Bold-Italic";
  src: url("./ui/assets/fonts/Carnero-BoldItalic.eot");
  src: url("./ui/assets/fonts/Carnero-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./ui/assets/fonts/Carnero-BoldItalic.svg#Carnero-BoldItalic")
      format("svg"),
    url("./ui/assets/fonts/Carnero-BoldItalic.ttf") format("truetype"),
    url("./ui/assets/fonts/Carnero-BoldItalic.woff") format("woff"),
    url("./ui/assets/fonts/Carnero-BoldItalic.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Carnero-Bold";
  src: url("./ui/assets/fonts/Carnero-Bold.eot");
  src: url("./ui/assets/fonts/Carnero-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./ui/assets/fonts/Carnero-Bold.svg#Carnero-Bold") format("svg"),
    url("./ui/assets/fonts/Carnero-Bold.ttf") format("truetype"),
    url("./ui/assets/fonts/Carnero-Bold.woff") format("woff"),
    url("./ui/assets/fonts/Carnero-Bold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Carnero-Book-Italic";
  src: url("./ui/assets/fonts/Carnero-BookItalic.eot");
  src: url("./ui/assets/fonts/Carnero-BookItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./ui/assets/fonts/Carnero-BookItalic.svg#Carnero-BookItalic")
      format("svg"),
    url("./ui/assets/fonts/Carnero-BookItalic.ttf") format("truetype"),
    url("./ui/assets/fonts/Carnero-BookItalic.woff") format("woff"),
    url("./ui/assets/fonts/Carnero-BookItalic.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Carnero-Book";
  src: url("./ui/assets/fonts/Carnero-Book.eot");
  src: url("./ui/assets/fonts/Carnero-Book.eot?#iefix")
      format("embedded-opentype"),
    url("./ui/assets/fonts/Carnero-Book.svg#Carnero-Book") format("svg"),
    url("./ui/assets/fonts/Carnero-Book.ttf") format("truetype"),
    url("./ui/assets/fonts/Carnero-Book.woff") format("woff"),
    url("./ui/assets/fonts/Carnero-Book.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Carnero-Italic";
  src: url("./ui/assets/fonts/Carnero-Italic.eot");
  src: url("./ui/assets/fonts/Carnero-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("./ui/assets/fonts/Carnero-Italic.svg#Carnero-Italic") format("svg"),
    url("./ui/assets/fonts/Carnero-Italic.ttf") format("truetype"),
    url("./ui/assets/fonts/Carnero-Italic.woff") format("woff"),
    url("./ui/assets/fonts/Carnero-Italic.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Carnero-Light-Italic";
  src: url("./ui/assets/fonts/Carnero-LightItalic.eot");
  src: url("./ui/assets/fonts/Carnero-LightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./ui/assets/fonts/Carnero-LightItalic.svg#Carnero-LightItalic")
      format("svg"),
    url("./ui/assets/fonts/Carnero-LightItalic.ttf") format("truetype"),
    url("./ui/assets/fonts/Carnero-LightItalic.woff") format("woff"),
    url("./ui/assets/fonts/Carnero-LightItalic.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Carnero-Light";
  src: url("./ui/assets/fonts/Carnero-Light.eot");
  src: url("./ui/assets/fonts/Carnero-Light.eot?#iefix")
      format("embedded-opentype"),
    url("./ui/assets/fonts/Carnero-Light.svg#Carnero-Light") format("svg"),
    url("./ui/assets/fonts/Carnero-Light.ttf") format("truetype"),
    url("./ui/assets/fonts/Carnero-Light.woff") format("woff"),
    url("./ui/assets/fonts/Carnero-Light.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Carnero-SemiBold-Italic";
  src: url("./ui/assets/fonts/Carnero-SemiBoldItalic.eot");
  src: url("./ui/assets/fonts/Carnero-SemiBoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./ui/assets/fonts/Carnero-SemiBoldItalic.svg#Carnero-SemiBoldItalic")
      format("svg"),
    url("./ui/assets/fonts/Carnero-SemiBoldItalic.ttf") format("truetype"),
    url("./ui/assets/fonts/Carnero-SemiBoldItalic.woff") format("woff"),
    url("./ui/assets/fonts/Carnero-SemiBoldItalic.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Carnero-SemiBold";
  src: url("./ui/assets/fonts/Carnero-SemiBold.eot");
  src: url("./ui/assets/fonts/Carnero-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("./ui/assets/fonts/Carnero-SemiBold.svg#Carnero-SemiBold") format("svg"),
    url("./ui/assets/fonts/Carnero-SemiBold.ttf") format("truetype"),
    url("./ui/assets/fonts/Carnero-SemiBold.woff") format("woff"),
    url("./ui/assets/fonts/Carnero-SemiBold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html {
  scroll-behavior: smooth;
  background-color: #000000;
}

body {
  margin: 0;
  font-family: "Carnero-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiTooltip-tooltip {
  font-family: "Carnero-Regular", "sans-serif" !important;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
  box-shadow: inset 0 0 5px white;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c1101a;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e30613;
}
